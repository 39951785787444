import React, { useCallback, useEffect, useRef, useState } from "react"
import { withTrans } from "../../i18n/withTrans"
import Seo from "../../components/seo"
import { Typography } from "@material-ui/core"
import * as htmlToImg from "html-to-image"
import "./style.scss"
import axios from "axios"

const IdCardPage = () => {
  const ref = useRef()
  const [downloadClicked, setDownloadClicked] = useState(false)
  const [idCardData, setIdCardData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    linkedin: "",
    astroPosition: {
      id: -1,
      position: "",
    },
    photoURI:
      "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/xbHBL1hj_HQf9V_h_kP2teeFwGN0DM3V-6xIZE9_jNs.jpg",
  })

  const getIDCardData = async id => {
    const { data } = await axios.get(
      `${process.env.GATSBY_API_URL}/api/scpublic/astro/user?userCode=${id}`
    )
    if (data) {
      setIdCardData(data)
    }
  }

  const handleDownloadPage = useCallback(
    async fileName => {
      if (ref.current === null) {
        return
      }

      await htmlToImg
        .toPng(ref.current, { cacheBust: true })
        .then(dataUrl => {
          const link = document.createElement("a")
          link.download = `${fileName.replace(" ", "-")}.png`
          link.href = dataUrl
          link.click()
        })
        .catch(err => {})
      setTimeout(function () {
        setDownloadClicked(false)
      }, 1000)
    },
    [ref]
  )

  const changeUIDownload = () => {
    setDownloadClicked(true)
  }

  useEffect(() => {
    if (downloadClicked) {
      handleDownloadPage(idCardData.fullName)
    }
  }, [downloadClicked])

  useEffect(() => {
    getIDCardData(
      new URLSearchParams(
        typeof window !== `undefined` ? window.location.search : ``
      ).get("userCode")
    )
  }, [])

  return (
    <div
      className="id-card-page"
      style={{
        background: "linear-gradient(180deg, #202b4d 0%, #000 100%)",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        color: "#fff",
        justifyContent: "center",
      }}
    >
      <Seo id={"home"} title={idCardData.fullName} />
      <div className="content-box" ref={ref} id="id-card-page">
        <div
          className="header-part"
          style={{ display: downloadClicked ? "none" : "flex" }}
        >
          <img
            src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/cbTqsF9OTmkduAzXpDFwicefG_xsru2EtRGqLngFugo.jpg"
            width={55}
            alt="asetku"
          />
          <div className="download-icon" onClick={changeUIDownload}>
            <img
              src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/jFeeooEsLDc9PusN5lyVXRIwibM0ek53q5u0V20FhWk.jpg"
              width={18}
              alt="asetku-download-icon"
            />
            <Typography className="download-text">Download</Typography>
          </div>
        </div>
        <div className={`ava-name-part ${downloadClicked ? "download" : ""}`}>
          <img
            src={idCardData.photoURI}
            className={`ava-img ${downloadClicked ? "download" : ""}`}
          />
          <div className="name-title">
            <Typography className="name">{idCardData.fullName}</Typography>
            <Typography className="title">
              {idCardData.astroPosition.position}
            </Typography>
            <Typography
              className="linked-in"
              style={{ display: !downloadClicked ? "none" : "flex" }}
            >
              {`Linkedin: ${idCardData.fullName}`}
            </Typography>
          </div>
        </div>

        <div
          className="ctas-part"
          style={{ display: downloadClicked ? "none" : "flex" }}
        >
          {idCardData.phoneNumber.length != 0 ||
          idCardData.phoneNumber != "null" ? (
            <a
              href={`tel:${idCardData.phoneNumber}`}
              target="_blank"
              className="channel-box"
            >
              <div className="icon">
                <img
                  src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/pGOmiczSzY7amh708LQ-6xxEeqOEQzmJ2iii2J6BI8U.jpg"
                  width={40}
                  style={{ borderRadius: "13px" }}
                />
              </div>
              <div className="title-desc">
                <div className="desc">{idCardData.phoneNumber}</div>
              </div>
            </a>
          ) : (
            <></>
          )}
          {idCardData.email.length != 0 || idCardData.email != "null" ? (
            <a
              href={`mailto:${idCardData.email}`}
              target="_blank"
              className="channel-box"
            >
              <div className="icon">
                <img
                  src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/CuUEx47rEABMkNRDjCPh2BS1wvo_kc2tYcsD4SCS5wg.jpg"
                  width={40}
                  style={{ borderRadius: "13px" }}
                />
              </div>
              <div className="title-desc">
                <div className="desc">{idCardData.email}</div>
              </div>
            </a>
          ) : (
            <></>
          )}
          {idCardData.linkedin.length != 0 || idCardData.linkedin != "null" ? (
            <a
              href={idCardData.linkedin}
              target="_blank"
              className="channel-box"
            >
              <div className="icon">
                <img
                  src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/KChe33yB2AtoDW7jQMFO-VcoY-8J0dr9zQ_x1176R4g.jpg"
                  width={40}
                  style={{ borderRadius: "13px" }}
                />
              </div>
              <div className="title-desc">
                <div className="desc">{idCardData.fullName}</div>
              </div>
            </a>
          ) : (
            <></>
          )}
          <a
            href="https://maps.app.goo.gl/B1oJ3Ae2B7cm6tzYA"
            target="_blank"
            className="channel-box"
          >
            <div className="icon">
              <img
                src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/NtjhK5GniFyZSCKtJIyTOdZ89k7r7Q8Ymt_lmPO9nKo.jpg"
                width={40}
                style={{ borderRadius: "13px" }}
              />
            </div>
            <div className="title-desc">
              <div className="title">AsetKu - PT Pintar Inovasi Digital</div>
              <div className="desc">
                Sequis Tower, Lantai 18 Unit 1-7. Jl. Jenderal Sudirman No.71
                Jakarta Selatan 12190
              </div>
            </div>
          </a>
        </div>

        <div
          className="bottom-part"
          style={{
            display: !downloadClicked ? "none" : "flex",
          }}
        >
          <div className="email-phone-part">
            <div>{idCardData.email}</div>
            <div>{idCardData.phoneNumber}</div>
          </div>
          <div className="address-part">
            <div className="title">AsetKu - PT Pintar Inovasi Digital</div>
            <div className="desc">
              Sequis Tower, Lantai 18 Unit 1-7. Jl. Jenderal Sudirman No.71
              Jakarta Selatan 12190
            </div>
          </div>
          <img
            src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/cbTqsF9OTmkduAzXpDFwicefG_xsru2EtRGqLngFugo.jpg"
            width={55}
            alt="asetku"
          />
        </div>
      </div>
    </div>
  )
}

export default withTrans(IdCardPage)
